import revive_payload_client_qfMY62i7oT from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tYUyzmSmKK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OJKu2O6evl from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1iYV0iG4fa from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@types+node@20.5.1_sass@1.79._qcqtujvha466dxfcuuxkfnlfwm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8zloumjIe7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6jv0xp8ceo from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gOi515QROY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCy1s4KC6w from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M6CG7kSrTJ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.5_typescript@5.6.2_vue@3.5.10_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_G2XCISDP89 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.5.1_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__d5tbkgfg7s6c7t4cp5qwnpzhwi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_qfMY62i7oT,
  unhead_tYUyzmSmKK,
  router_OJKu2O6evl,
  _0_siteConfig_1iYV0iG4fa,
  payload_client_8zloumjIe7,
  navigation_repaint_client_6jv0xp8ceo,
  check_outdated_build_client_gOi515QROY,
  chunk_reload_client_kCy1s4KC6w,
  plugin_vue3_M6CG7kSrTJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_G2XCISDP89
]